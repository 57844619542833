<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Author/Edit Standards</h3>

	<p><span v-html="app_name"/> allows framework authors to take advantage of the CASE specifications to meet the needs of many stakeholder groups.
		<ul>
			<li>For information about the basics, try our <span v-html="link('add_item', 'Add New Items')"></span> user guide to learn about the field definitions for items and common use cases. </li>
			<li>To learn about format requirements for the most popular additional fields, try our <span v-html="link('supplemental_info', 'Supplemental Information')"></span> user guide to learn about what’s possible and the DOs and DONTs of markdown, LaTeX, and HTML editing.</li>
			<li>To think about how to organize framework items in more than one way, try our <span v-html="link('supplemental_branches', 'Supplemental Branches')"></span> use guide.</li>
		</ul>
	</p>

	
	<p>To get inspiration from others around best practices or common stakeholder needs that arise from publishing using CASE, consider these questions:
		

		<ul>
			<li>How can a <strong>learning progression</strong> be added to the framework to better support vertical articulation across grade spans? See <a href="https://casenetwork.1edtech.org/6cb49550-0f81-11ee-bc59-0242ac160002/a430cb1e-106e-11ee-970d-0242ac1a0002/502" target="_blank"> North Dakota’s ELA Learning Progressions</a></li>
			<img alt="North Dakota ELA example image" srcset="/docimages/north_dakota_ela.png 1.8x " class="k-help-img block">
			
		
			<li>How can <strong>internal associations</strong> within a framework help stakeholders see the connections that might otherwise be hard to find in a static PDF or excel document? See <a href="https://casenetwork.1edtech.org/8bbfb0aa-1273-4bf4-8217-31bffd0107b4/7738ed8f-cd97-40ce-a143-309130cba3c7/405" target="_blank"> Arizona’s Science framework </a> with internal associations between academic content and core ideas. </li>
			<img alt="Internal associations example image" srcset="/docimages/az_science.png 1.3x " class="k-help-img block">
			
			
			<li>How can <strong>associations across frameworks</strong> help stakeholders make cross-curricular connections that might otherwise be hard to find in a static PDF or excel document?  See <a href="https://casenetwork.1edtech.org/a446e74c-463e-11e7-94f5-b49cee8b2d8c/3a257174-4640-11e7-90b2-b3f2608a6b4e/1566" target="_blank"> Georgia Social Studies framework</a> associated to the WIDA English Language Development framework. </li>
			<img alt="Georgia Social Studies framework associations example image" srcset="/docimages/georgia_social.png 1.6x " class="k-help-img block">
		

			<li>How can notes, <strong>supplemental information</strong> (context for a standard that may reference math type, images, tables) and other metadata be used to provide more background about the learning outcomes? See images.</li>
			<br>
			<img alt="Supplemental information example image" srcset="/docimages/fundamentals.png 1x " class="k-help-img block">
			<br>
			<img alt="Supplemental information example image 2" srcset="/docimages/dimensions_of_science.png 1x " class="k-help-img block">
			<br>
			<img alt="Supplemental information example image 3" srcset="/docimages/table_2.png 1x " class="k-help-img block">
			<li>Who will need <Strong>Framework Reviewer</Strong> and <strong>Framework Editor</strong> access to be able to view, comment, or edit the private or sandbox versions of new frameworks? See <span v-html="link('reviewing', 'Reviewing Frameworks')"></span>  chapter.</li>
		</ul>
	</p>

	<p><strong>NOTE:</strong> For all other errors or if you are unable to resolve an error, e-mail <a href="mailto:support@commongoodlt.com">support@commongoodlt.com</a>
 for further help.</p>




</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	